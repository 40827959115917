let config: any = {};

if (process.env.NODE_ENV == 'development') {
  config = {
    "AUTH_URL": "http://simplicity/tenantapi/login",
    "BASE_API_URL": "http://simplicity/tenantapi/"
  };
} else {
  config = {
    "AUTH_URL": "https://rstarmanagement.simpli-city.com/tenantapi/login",
    "BASE_API_URL": "https://rstarmanagement.simpli-city.com/tenantapi/"
  };
}


export const endPoint = "https://cdn.cardknox.com/ifields/2.12.2201.2101/ifield.htm";
export const account = {
  xKey: "",
  xSoftwareName: "cardknox-ifields",
  xSoftwareVersion: "1.0.0"
};


export default config;